<template>
  <el-dialog
    title="在线签字"
    :visible.sync="visible"
    :show-close="true"
    :close-on-click-modal="false"
  >
    <vue-esign
      class="sign-view"
      ref="esign"
      :width="800"
      :height="300"
      :isCrop="isCrop"
      :lineWidth="lineWidth"
      :lineColor="lineColor"
      :bgColor.sync="bgColor"
    />
    <div class="btn-list">
      <el-button type="danger" @click="reset">清空</el-button>
      <el-button type="primary" @click="save">保存</el-button>
    </div>
  </el-dialog>
</template>
<script>
import vueEsign from "vue-esign";
export default {
  name: "Sign",
  components: { vueEsign },
  props: {
    // 弹窗显示与否
    visible: {
      typeof: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lineWidth: 6,
      lineColor: "#000000",
      bgColor: "",
      resultImg: "",
      isCrop: false,
    };
  },
  methods: {
    // 清空画板
    reset() {
      this.$refs.esign.reset();
    },
    // 保存
    save() {
      // 可选配置参数 ，在未设置format或quality属性时可在生成图片时配置 例如： {format:'image/jpeg', quality: 0.5}
      // this.$refs.esign.generate({format:'image/jpeg', quality: 0.5})
      this.$refs.esign
        .generate()
        .then((res) => {
          this.$emit("finsih", res);
        })
        .catch((err) => {
          // 画布没有签字时会执行这里 'Not Signned'
          this.$message.error("您还未完成签字，请签字完成后保存");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__body {
  padding-top: 0;
  padding-bottom: 10px;
}
.sign-view {
  border: 1px dashed #999;
}
.btn-list {
  margin-top: 10px;
  text-align: right;
}
</style>
