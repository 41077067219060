import request from '@/until/request.js'
import axios from 'axios'
import qs from 'qs'
import {uploadUrl} from '../../settting'
const prefixName = '/base/api/'
// 密码登录api
export function loginPassWord(data){
    return axios.post(process.env.VUE_APP_BASE_API + '/login', qs.stringify(data),
    {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    })
}
// 注册店铺api
export function registerShop(result){
  return request({
    url: prefixName + 'registered',
    method: 'post',
    data: result
  })
}

// 获取验证码登录api
export function GetVerificationCode(result){
  return request({
    url: '/gm/api/mobile',
    method: 'get',
    params: { ...result }
  })
}
// 忘记编码api
export function GetOrganizationCode(result){
  return request({
    url: prefixName + 'forgetCode',
    method: 'put',
    data: result
  })
}
// 重置密码
export function changePassword(result){
  return request({
    url: prefixName + 'reset/password',
    method: 'PUT',
    data: result
  })
}
// 查询仓库锁定状态
export function getLock(result){
  return request({
    url: '/gm/global/getLock',
    method: 'get',
    params: { ...result }
  })
}
// 获取经手人
export function getUserCustom(result){
  return request({
    url: '/base/user-custom/param',
    method: 'get',
    params: { ...result }
  })
}
// 获取价格模板
export function getTemplate(result){
  return request({
    url: '/gm/global/getTemplate',
    method: 'get',
    params: { ...result }
  })
}
// 获取价格模板
export function getTodayReport(){
  return request({
    url: 'order/webOrderRpt/overview',
    method: 'get'
  })
}
export function uploadFile(data){
  return request({
    url: uploadUrl,
    method: 'post',
    headers:{ 'Content-Type': 'multipart/form-data' },
    data: data
  })
  // return axios.post(process.env.VUE_APP_BASE_API + '/login', data,
  // {
  //   headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  // })
}
// 获取验证码登录api
export function checkCode(result){
  return request({
    url: '/base/api/checkCode',
    method: 'get',
    params: { ...result }
  })
}