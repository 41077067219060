<template>
  <div>
    <mia-navback @back="back" :isShowSave="!isView" @submit="submitForm" />
    <div class="header">
      <el-form :inline="true">
        <el-form-item label="台账编号:">
          {{ emeLedger.no }}
        </el-form-item>
        <el-form-item v-if="emeLedger.updateDate" label="修改时间:">
          {{ emeLedger.updateDate }}
        </el-form-item>
        <el-form-item v-if="emeLedger.updateUserName" label="最后修改人:">
          {{ emeLedger.updateUserName }}
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <table>
        <caption style="font-size: 24px; font-weight: 600; margin-bottom: 20px">
          农药应急防护与应急处置记录
        </caption>
        <tbody>
          <tr>
            <td style="font-size: 20px; font-weight: 600">
              农药季度销售及回访情况统计记录
            </td>
          </tr>
          <tr>
            <td style="text-align: left; padding-left: 50px">
              <div>
                <span class="title">销售统计：</span>
                <span style="color: red"
                  >本季度销售额将在下个季度前三日开放编辑，请您按时核对，避免数据有误</span
                >
              </div>
              <div style="padding-left: 30px">
                <p>
                  {{ emeLedger.name }}共向<el-input
                    style="width: 100px"
                    type="number"
                    v-model="emeLedger.sale.customerNum"
                    placeholder=""
                  ></el-input>
                  户客户销售了农药产品，销售农药产品总量
                  <el-input
                    style="width: 100px"
                    type="number"
                    v-model="emeLedger.sale.saleTotal"
                    placeholder=""
                  ></el-input>
                  千克(kg)，其中:
                </p>
                <div
                  v-for="(type, index) in types"
                  :key="type.name"
                  style="margin-top: 10px"
                >
                  <span style="display: inline-block; width: 132px"
                    >{{ type.name }}：</span
                  ><el-input
                    style="width: 100px"
                    type="number"
                    v-model="type.value"
                  ></el-input>
                  千克(kg) <span v-if="types.length - 1 == index">。</span
                  ><span v-else>，</span>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td style="text-align: left; padding-left: 50px">
              <div>
                <span class="title">回访内容：</span>
              </div>
              <div style="padding-left: 30px">
                <div
                  v-for="(item, index) in huifangs"
                  :key="item.name"
                  style="margin-top: 10px"
                >
                  <el-checkbox
                    v-model="item.value"
                    :true-label="0"
                    :false-label="1"
                  ></el-checkbox>
                  {{ item.name }}
                  <span v-if="huifangs.length - 1 == index">。</span
                  ><span v-else>；</span>
                </div>
              </div>

              <div>
                <span class="title">反馈信息（选填）</span>
              </div>
              <el-input
                type="textarea"
                v-model="emeLedger.sale.feedback"
                rows="4"
              ></el-input>
              <div>
                <span class="title">本季度农药安全事故发生情况</span>
              </div>
              <p>
                <el-checkbox
                  v-model="emeLedger.isSell"
                  true-label="0"
                  false-label=""
                  @change="sellItemChange($event, '0')"
                ></el-checkbox>
                售出农药未发生农药安全事故
              </p>
              <p>
                <el-checkbox
                  v-model="emeLedger.isSell"
                  true-label="1"
                  false-label=""
                  @change="sellItemChange($event, '1')"
                >
                </el-checkbox>
                售出农药发生{{
                  emeLedger.accidentList.length
                }}起农药安全事故（详见：农药安全事故应急处置措施记录）。
              </p>
              <div style="text-align: right">
                记录人：<el-input
                  style="width: 100px"
                  type="text"
                  v-model="emeLedger.sale.recordName"
                  placeholder=""
                ></el-input>
                时间：<el-date-picker
                  v-model="emeLedger.sale.recordDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="emeLedger.isSell == '1'">
        <table v-for="(item, index) in emeLedger.accidentList" :key="index">
          <caption style="text-align: left; font-size: 20px">
            N{{
              index + 1
            }}：
          </caption>
          <tbody>
            <tr>
              <td colspan="6" style="font-size: 20px; font-weight: 600">
                农药安全事故应急处置措施记录（一事一记录）
                <div
                  v-if="emeLedger.accidentList.length > 1"
                  style="float: right"
                >
                  <div class="delete-btn" @click="deleteAccident(index)">
                    删除
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>事故接报时间</td>
              <td>
                <el-date-picker
                  v-model="item.callDate"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </td>
              <td>报告人姓名</td>
              <td>
                <el-input type="text" v-model="item.notifyName"></el-input>
              </td>
              <td>报告人电话</td>
              <td>
                <el-input type="text" v-model="item.notifyContact"></el-input>
              </td>
            </tr>
            <tr>
              <td>接（受）报人姓名</td>
              <td>
                <el-input type="text" v-model="item.callName"></el-input>
              </td>
              <td colspan="2">接（受）报人电话</td>
              <td colspan="2">
                <el-input type="text" v-model="item.callContact"></el-input>
              </td>
            </tr>
            <tr>
              <td colspan="2">农药安全事故（件）类型</td>
              <td colspan="4">
                <el-select
                  v-model="item.type"
                  style="width: 100%"
                  placeholder="请选择季度"
                >
                  <el-option
                    v-for="(type, Index) in accidentTypes"
                    :key="Index"
                    :label="type.name"
                    :value="type.id"
                  />
                </el-select>
              </td>
            </tr>
            <tr>
              <td colspan="6">农药安全事故（件）简要情况描述</td>
            </tr>
            <tr>
              <td colspan="6">
                <el-input
                  type="textarea"
                  v-model="item.description"
                  rows="4"
                  placeholder="综合简要描述即可"
                ></el-input>
              </td>
            </tr>
            <tr>
              <td colspan="3">采取的应急访用户和应急处置措施</td>
              <td colspan="3">农药安全事故（件）报告情况记录</td>
            </tr>
            <tr>
              <td colspan="3">
                <el-input
                  type="textarea"
                  v-model="item.measure"
                  rows="4"
                ></el-input>
              </td>
              <td colspan="3">
                <el-input
                  type="textarea"
                  v-model="item.record"
                  rows="4"
                ></el-input>
              </td>
            </tr>
            <tr>
              <td>处置结果</td>
              <td colspan="5">
                <el-input
                  type="text"
                  v-model="item.result"
                  placeholder="例：x年x月x日事故（件）达成协议，已妥善解决"
                ></el-input>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="add-btn" @click="addAccident">新增事故处置记录</div>
      </div>
    </div>
    <Sign
      :visible="signatureVisible"
      @finsih="signFisish"
      @close="signatureVisible = false"
    ></Sign>
  </div>
</template>
<script>
import Sign from "@/components/Sign";
import { uploadFile } from "@/api/common";
import { imgUrl } from "../../../../settting";
import {
  getEmeLedgerCode,
  getEmeLedgerDetails,
  addEmeLedger,
  editEmeLedger,
} from "@/api/emeLedger";
import moment from "moment";
export default {
  components: { Sign },
  data() {
    return {
      emeLedger: {
        isSell: "",
        sale: {},
        accidentList: [],
      },
      checkIsSellList: [], // 是否售出并发生事故
      checkPlace: [],
      isView: false,
      signatureVisible: false,
      types: [],
      huifangs: [],
      accidentTypes: [
        {
          name: "使用农药造成的农作物药害事故",
          id: "使用农药造成的农作物药害事故",
        },
        {
          name: "使用农药造成的水生生物、蜜蜂、蚕等有益生物死亡事故",
          id: "使用农药造成的水生生物、蜜蜂、蚕等有益生物死亡事故",
        },
        {
          name: "农药保管、使用环节发生的人畜中毒事件",
          id: "农药保管、使用环节发生的人畜中毒事件",
        },
        {
          name: "农药及农药包装废弃物引发的污染事件",
          id: "农药及农药包装废弃物引发的污染事件",
        },
      ],
    };
  },
  methods: {
    init(id, view) {
      if (id) {
        getEmeLedgerDetails(id).then((res) => {
          if (res.code === 1) {
            this.emeLedger = res.data;
            this.types = JSON.parse(res.data.sale.saleText);
            this.huifangs = JSON.parse(res.data.sale.interviewText);
            this.isView = view;
          }
        });
      } else {
        getEmeLedgerCode().then((res) => {
          if (res.code == 1) {
            this.emeLedger = {
              ...this.emeLedger,
              no: res.data,
              checkDate: moment().format("YYYY-MM-DD"),
            };
          }
        });
      }
    },
    getImg(url) {
      return imgUrl + url;
    },
    sellItemChange(evt, val) {
      if (evt) {
        this.checkIsSellList = [val];
      }
      if (val == "1" && this.emeLedger.accidentList.length == 0) {
        this.addAccident();
      }
    },
    addAccident() {
      if (this.emeLedger.accidentList.length > 0) {
        const lastAccident =
          this.emeLedger.accidentList[this.emeLedger.accidentList.length - 1];
        if (!lastAccident.callDate) {
          return this.$message.error("请填写事故接报时间");
        }
        if (!lastAccident.notifyName) {
          return this.$message.error("请填写报告人姓名");
        }
        if (!lastAccident.notifyContact) {
          return this.$message.error("请填写报告人电话");
        }
        if (!lastAccident.callName) {
          return this.$message.error("请填写接报人姓名");
        }
        if (!lastAccident.callContact) {
          return this.$message.error("请填写接报人电话");
        }
      }
      this.emeLedger = {
        ...this.emeLedger,
        accidentList: this.emeLedger.accidentList.concat([
          {
            notifyName: "",
            notifyContact: "",
            callName: "",
            callContact: "",
            callDate: moment().format("YYYY-MM-DD"),
            type: "",
            description: "",
            measure: "",
            record: "",
            result: "",
          },
        ]),
      };
    },
    deleteAccident(index) {
      this.emeLedger.accidentList.splice(index, 1);
      this.emeLedger = {
        ...this.emeLedger,
        accidentList: this.emeLedger.accidentList,
      };
    },
    back() {
      this.$emit("back");
    },
    checkPlaceChange(val) {
      this.checkPlace = val;
    },
    submitForm() {
      if (!this.emeLedger.isSell) {
        return this.$message.error("请选择本季度农药安全事故发生情况");
      }
      if (this.emeLedger.isSell == "1") {
        if (this.emeLedger.accidentList.length > 0) {
          const lastAccident =
            this.emeLedger.accidentList[this.emeLedger.accidentList.length - 1];
          if (!lastAccident.callDate) {
            return this.$message.error("请填写事故接报时间");
          }
          if (!lastAccident.notifyName) {
            return this.$message.error("请填写报告人姓名");
          }
          if (!lastAccident.notifyContact) {
            return this.$message.error("请填写报告人电话");
          }
          if (!lastAccident.callName) {
            return this.$message.error("请填写接报人姓名");
          }
          if (!lastAccident.callContact) {
            return this.$message.error("请填写接报人电话");
          }
          if (!lastAccident.type) {
            return this.$message.error("请选择事故类型");
          }
        }
      } else {
        // 如果为0那么清空事故
        this.emeLedger.accidentList = [];
      }
      this.emeLedger.sale.saleText = JSON.stringify(this.types);
      this.emeLedger.sale.interviewText = JSON.stringify(this.huifangs);
      console.log(this.emeLedger);
      if (this.emeLedger.id) {
        editEmeLedger(this.emeLedger).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.back();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        addEmeLedger(this.emeLedger).then((res) => {
          if (res.code === 1) {
            this.$message.success("操作成功");
            this.back();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    openSign() {
      this.signatureVisible = true;
    },
    signFisish(val) {
      const imgs = this.base64ImgtoFile(val);
      const formData = new FormData();
      formData.append("file", imgs);
      uploadFile(formData).then((res) => {
        if (res.code === 1) {
          this.emeLedger = { ...this.emeLedger, sign: res.url };
        }
      });
      this.signatureVisible = false;
    },
    base64ImgtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  width: 80%;
  margin: 0 auto;
  padding: 20px 50px;
  /deep/ .el-form-item {
    width: 32%;
    &:nth-child(1) {
      text-align: left;
    }
    &:nth-child(2) {
      text-align: center;
    }
    &:nth-child(3) {
      text-align: right;
    }
  }
}
/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #409eff;
  color: #409eff;
}
.content {
  text-align: center;
  width: 80%;
  margin: 0 auto;
  .title {
    font-weight: 600;
    font-size: 20px;
    display: inline-block;
    margin: 10px 0;
  }
  /deep/ .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
  /deep/ .el-date-editor {
    .el-input__prefix,
    .el-input__suffix {
      top: -4px;
    }
  }
}
table {
  width: 100%;
}
.inline-table {
  border: 0px;
}
table,
td {
  border-collapse: collapse;
  border: 1px solid #999;
}
td {
  padding: 15px;
  font-size: 14px;
}
.sign {
  width: 100px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  cursor: pointer;
}
.add-btn {
  border: 1px dashed #409eff;
  color: #409eff;
  width: 150px;
  padding: 10px 20px;
  border-radius: 5px;
  margin: 20px auto 0px auto;
  font-size: 14px;
  cursor: pointer;
}
.delete-btn {
  border: 1px dashed red;
  color: red;
  width: 60px;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}
</style>
